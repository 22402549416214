import React, { FC } from 'react'

import AppIconPNG from '../../assets/images/AppIcon.png'
import { useStyles } from '../../lib/styles/universal'

interface AppIconProps {}

export const AppIcon: FC<AppIconProps> = () => {
    const { classes } = useStyles()
    return (
        <img
            src={AppIconPNG}
            className={classes.appIcon}
            alt="HazAdapt app icon"
        />
    )
}
