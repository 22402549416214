import { Grid, Skeleton } from '@mui/material'
import React, { FC } from 'react'
import { AspectRatio } from 'react-aspect-ratio'

import { customBorderRadius } from '../../lib/styles/universal'
import { useWindowSizeExact } from '../../lib/utils'
import { HazardCard, HazardCardDTO } from '../molecules'

export interface HazardCardGridProps {
    data: HazardCardDTO[]
    onHazardPress(slug: string, id: number, name: string): void
    onOptionsPress(id: number): void
    onOptionsClose(): void
    onShare(id: number): void
    openMenu?: number
    loading?: boolean
}

export const HazardCardGrid: FC<HazardCardGridProps> = (
    props: HazardCardGridProps
) => {
    const xsWindowExact = useWindowSizeExact('xs')
    const smallWindowExact = useWindowSizeExact('sm')
    const mediumWindowExact = useWindowSizeExact('md')
    const largeWindowExact = useWindowSizeExact('lg')
    const xlWindowExact = useWindowSizeExact('xl')
    const aspectRatio = xsWindowExact ? 2 : smallWindowExact ? 4 : 1
    const numSkeletons = xlWindowExact
        ? 12
        : largeWindowExact
        ? 8
        : mediumWindowExact
        ? 6
        : 4

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
    const onOptionsPress = (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        id: number
    ) => {
        setAnchorEl(e.currentTarget)
        props.onOptionsPress(id)
    }
    const onOptionsClose = () => {
        setAnchorEl(null)
        props.onOptionsClose()
    }

    return (
        <Grid container alignItems="center" spacing={2}>
            {props.loading
                ? Array.from(Array(numSkeletons)).map((_, i) => (
                      <Grid
                          item
                          xs={12}
                          md={4}
                          lg={3}
                          xl={2}
                          key={`hazardCard_placeholder_${i + 1}`}
                      >
                          <AspectRatio
                              ratio={aspectRatio}
                              style={{ width: '100%' }}
                          >
                              <Skeleton
                                  variant="rectangular"
                                  width="100%"
                                  height="100%"
                                  sx={{ borderRadius: customBorderRadius }}
                              />
                          </AspectRatio>
                      </Grid>
                  ))
                : props.data.map((hazard: HazardCardDTO) => (
                      <Grid
                          key={`hazardCard_${hazard.id}`}
                          item
                          xs={12}
                          md={4}
                          lg={3}
                          xl={2}
                      >
                          <HazardCard
                              {...hazard}
                              onPress={props.onHazardPress}
                              onOptionsPress={onOptionsPress}
                              onOptionsClose={onOptionsClose}
                              onShare={props.onShare}
                              anchorEl={
                                  hazard.id === props.openMenu ? anchorEl : null
                              }
                              optionsOpen={hazard.id === props.openMenu}
                          />
                      </Grid>
                  ))}
        </Grid>
    )
}
