import { combineReducers } from '@reduxjs/toolkit'

import HazardReducer from '../slices/HazardSlice'

export const rootReducer = combineReducers({
    hazards: HazardReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
