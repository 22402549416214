import { HazardOverview } from '@hazadapt-git/public-core-base'
import { Link } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { Box } from '@mui/system'
import React, { FC } from 'react'

import { customDarkBlueColor } from '../../lib/styles/universal'
import { useWindowSizeUp } from '../../lib/utils'
import { CompanyLogo, Searchbar, SearchResultMenuItem } from '../atoms'

interface SiteHeaderProps {
    rootHref: string
    searchQuery: string
    onSearchQueryChange(value: string): void
    onLogoPress?: React.MouseEventHandler
    options: HazardOverview[]
    onOptionPress(slug: string, id: number, name: string): void
    loadingOptions?: boolean
    onSearchQuerySubmit(): void
}

export const SiteHeader: FC<SiteHeaderProps> = (props: SiteHeaderProps) => {
    const { classes } = useLocalStyles()
    const smallWindowOrLarger = useWindowSizeUp('sm')

    return (
        <Box
            width="100%"
            bgcolor={customDarkBlueColor}
            paddingY="1.5rem"
            display="flex"
            alignItems="center"
            flexDirection={smallWindowOrLarger ? 'row' : 'column'}
        >
            <Link href={props.rootHref}>
                <CompanyLogo
                    dark
                    onClick={props.onLogoPress}
                    width="8rem"
                    style={{
                        marginBottom: smallWindowOrLarger ? undefined : '1rem',
                        marginLeft: smallWindowOrLarger ? '2rem' : 0,
                        cursor: 'pointer',
                    }}
                />
            </Link>

            <div
                className={classes.searchbarAndProfile}
                style={{
                    width: smallWindowOrLarger ? undefined : '90%',
                    paddingRight: smallWindowOrLarger ? '1rem' : 0,
                    flexGrow: 1,
                    marginRight: smallWindowOrLarger ? '2rem' : 0,
                }}
            >
                <Searchbar
                    value={props.searchQuery}
                    onChange={(e) => props.onSearchQueryChange(e.target.value)}
                    sx={{
                        marginLeft: smallWindowOrLarger ? '1rem' : 0,
                        marginRight: smallWindowOrLarger ? '1rem' : 0,
                    }}
                    options={props.options.map(
                        (h) =>
                            ({
                                id: h.id,
                                icon: h.icon.src,
                                name: h.name,
                                slug: h.slug,
                            } as SearchResultMenuItem)
                    )}
                    onOptionPress={props.onOptionPress}
                    variant="outlined"
                    loading={props.loadingOptions}
                    onQuerySubmit={props.onSearchQuerySubmit}
                />
            </div>
        </Box>
    )
}

const useLocalStyles = makeStyles()({
    searchbarAndProfile: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'stretch',
    },
})
