import React, { FC } from 'react'

import { HazardCategory } from '../molecules'

export interface HazardCategoryGridProps {
    categories: string[]
    activeCategories: string[]
    onCategoryPress(category: string): void
}

export const HazardCategoryGrid: FC<HazardCategoryGridProps> = (
    props: HazardCategoryGridProps
) => (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        {props.categories.map((c: string, index: number) => (
            <div
                key={`hazardCategory_${c}`}
                style={{
                    marginLeft: index > 0 ? '1rem' : 0,
                    marginRight:
                        index < props.categories.length - 1 ? '1rem' : 0,
                }}
            >
                <HazardCategory
                    name={c}
                    active={props.activeCategories.includes(c)}
                    onPress={() => props.onCategoryPress(c)}
                />
            </div>
        ))}
    </div>
)
