import { makeStyles } from 'tss-react/mui'
import React, { CSSProperties, FC } from 'react'
import ReactMarkdown, { UrlTransform } from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import classNames from 'classnames'

interface MarkdownProps {
    containerClassName?: string
    onLinkPress?(url: string): any | Promise<any>
    content: string
    styleOverrides?: {
        p?: CSSProperties
        h1?: CSSProperties
        h2?: CSSProperties
        h3?: CSSProperties
        h4?: CSSProperties
        h5?: CSSProperties
        li?: CSSProperties
        ul?: CSSProperties
        ol?: CSSProperties
        a?: CSSProperties
        blockquote?: CSSProperties
        strong?: CSSProperties
    }
    urlTransform?: UrlTransform
}

export const Markdown: FC<MarkdownProps> = (props: MarkdownProps) => {
    const { classes: mdClasses } = useMdStyles()

    return (
        <div>
            <ReactMarkdown
                className={classNames(
                    mdClasses.container,
                    props.containerClassName
                )}
                urlTransform={props.urlTransform}
                rehypePlugins={[rehypeRaw]}
                components={{
                    h1: ({ node, children, ...elemProps }) => (
                        <h1
                            {...elemProps}
                            className={mdClasses.h1}
                            style={props.styleOverrides?.h1}
                            children={children}
                        />
                    ),
                    h2: ({ node, children, ...elemProps }) => (
                        <h2
                            {...elemProps}
                            className={mdClasses.h2}
                            style={props.styleOverrides?.h2}
                            children={children}
                        />
                    ),
                    h3: ({ node, children, ...elemProps }) => (
                        <h3
                            {...elemProps}
                            className={mdClasses.h3}
                            style={props.styleOverrides?.h3}
                            children={children}
                        />
                    ),
                    h4: ({ node, children, ...elemProps }) => (
                        <h4
                            {...elemProps}
                            className={mdClasses.h4}
                            style={props.styleOverrides?.h4}
                            children={children}
                        />
                    ),
                    h5: ({ node, children, ...elemProps }) => (
                        <h5
                            {...elemProps}
                            className={mdClasses.h5}
                            style={props.styleOverrides?.h5}
                            children={children}
                        />
                    ),
                    p: ({ node, ...elemProps }) => (
                        <p
                            className={mdClasses.p}
                            style={props.styleOverrides?.p}
                            {...elemProps}
                        />
                    ),
                    li: ({ node, ...elemProps }) => {
                        return (
                            <li
                                className={mdClasses.li}
                                style={props.styleOverrides?.li}
                                {...elemProps}
                            />
                        )
                    },
                    ul: ({ node, ...elemProps }) => {
                        return (
                            <ul
                                className={mdClasses.ul}
                                style={props.styleOverrides?.ul}
                                {...elemProps}
                            />
                        )
                    },
                    ol: ({ node, ...elemProps }) => (
                        <ol
                            className={mdClasses.ol}
                            style={props.styleOverrides?.ol}
                            {...elemProps}
                        />
                    ),
                    a: ({ node, children, ...elemProps }) => (
                        <a
                            {...elemProps}
                            className={mdClasses.a}
                            style={props.styleOverrides?.a}
                            children={children}
                        />
                    ),
                    blockquote: ({ node, ...elemProps }) => (
                        <blockquote
                            className={mdClasses.blockquote}
                            style={props.styleOverrides?.blockquote}
                            {...elemProps}
                        />
                    ),
                    strong: ({ node, ...elemProps }) => (
                        <strong
                            className={mdClasses.strong}
                            style={props.styleOverrides?.strong}
                            {...elemProps}
                        />
                    ),
                }}
                children={props.content}
            />
        </div>
    )
}

export const useMdStyles = makeStyles()({
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    p: {
        fontSize: '1rem',
        margin: 0,
        padding: '0 0 0.5rem',
        ':last-child': {
            padding: 0,
        },
    },
    ul: {
        paddingTop: 0,
        marginTop: 0,
        paddingBottom: 0,
        marginBottom: 0,
    },
    ol: {
        paddingTop: 0,
        marginTop: 0,
        paddingBottom: 0,
        marginBottom: 0,
    },
    li: {
        padding: '0 0.15625rem',
        margin: 0,
    },
    a: {
        color: 'blue',
    },
    blockquote: {
        paddingBottom: '0.625rem',
    },
    strong: {
        fontWeight: 600,
    },
    h1: {
        fontSize: '2rem',
        fontWeight: 600,
        color: 'black',
        margin: 0,
        padding: 0,
    },
    h2: {
        fontSize: '1.8rem',
        fontWeight: 600,
        color: 'black',
        margin: 0,
        padding: 0,
    },
    h3: {
        fontSize: '1.5rem',
        fontWeight: 600,
        color: 'black',
        margin: 0,
        padding: 0,
    },
    h4: {
        fontSize: '1.2rem',
        fontWeight: 500,
        color: 'black',
        margin: 0,
        padding: 0,
    },
    h5: {
        fontSize: '1rem',
        fontStyle: 'italic',
        color: 'black',
        margin: 0,
        padding: 0,
    },
})
