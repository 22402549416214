import {
    HazardOverview,
    LanguageCode,
    LoggingStatus,
    PrepCheckDTO,
} from '@hazadapt-git/public-core-base'
import { AxiosResponse } from 'axios'
import qs from 'query-string'
import React, { FC } from 'react'
import { Toaster } from 'react-hot-toast'
import {
    useNavigate,
    Routes,
    Route,
    RoutesProps,
    RouteProps,
    useLocation,
} from 'react-router-dom'
import voca from 'voca'

import {
    SiteHeader,
    Footer,
    HazardRedirect,
    PrepCheckRedirect,
} from '../components'
import { isa } from '../lib/api'
import { getEnvVars } from '../lib/config'
import { RootState, useAppSelector } from '../lib/store'
import { useStyles } from '../lib/styles/universal'
import { getHazard, getHazards, logEvent, searchHazards } from '../lib/utils'
import { LandingPage } from '../pages'

const env = getEnvVars()
export const Router: FC<RoutesProps> = (props: RoutesProps) => {
    const { classes } = useStyles()
    const location = useLocation()
    const params = qs.parse(location.search)
    const navigate = useNavigate()

    const hazards = useAppSelector((state: RootState) => state.hazards.hazards)
    const [searchQuery, setSearchQuery] = React.useState<string>('')
    const [suggestions, setSuggestions] =
        React.useState<HazardOverview[]>(hazards)
    const [loading, setLoading] = React.useState<boolean>(true)

    const [linkedHazard, setLinkedHazard] = React.useState<
        HazardOverview | null | undefined
    >()
    const [linkedPrepCheck, setLinkedPrepCheck] = React.useState<PrepCheckDTO>()

    React.useEffect(() => {
        const urlPieces = location.pathname.split('/').slice(1)
        if (urlPieces.length === 1) {
            const identifier = urlPieces[0]
            if (identifier) {
                let hazard: HazardOverview | undefined
                if (voca.isNumeric(identifier)) {
                    hazard = hazards.find(
                        (h) => h.id === parseInt(identifier, 10)
                    )
                } else {
                    hazard = hazards.find((h) => h.slug === identifier)
                }
                if (hazard) setLinkedHazard(hazard)
                else {
                    getHazard(identifier)
                        .then((res) => {
                            setLinkedHazard({
                                id: res.id,
                                name: res.name[LanguageCode.ENGLISH],
                                description:
                                    res.description[LanguageCode.ENGLISH],
                                slug: res.slug,
                                types: res.types,
                                tags: res.tags,
                                filters: res.filters,
                                icon: {
                                    ...res.icon,
                                    alt: res.icon.alt[LanguageCode.ENGLISH],
                                },
                                date_modified: new Date(),
                            })
                        })
                        .catch((err) => {
                            console.error(err)
                            window.location.href = `${env.frontendUriBase}/hazards`
                        })
                }
            } else {
                setLinkedHazard(null)
            }
        } else if (urlPieces.length > 1) {
            redirectBasedOnContentType(urlPieces)
        }
    }, [location.pathname, hazards])

    React.useEffect(() => {
        logEvent('OPEN_APP')
    }, [])

    React.useEffect(() => {
        if (params.query) {
            logEvent('SEARCH', { query: params.query })
        }
    }, [params])

    React.useEffect(() => {
        getHazards()
            .catch(console.error)
            .finally(() => setLoading(false))
    }, [])

    React.useEffect(() => {
        searchHazards(hazards, searchQuery).then(setSuggestions)
    }, [hazards, searchQuery])

    React.useEffect(() => {
        if (linkedHazard) {
            setTimeout(() => {
                window.location.href = `${env.frontendUriBase}/hazards/${linkedHazard.slug}`
            }, 2000)
        }
    }, [linkedHazard])

    const redirectBasedOnContentType = async (
        urlPieces: string[]
    ): Promise<void> => {
        const contentType = urlPieces[0]

        switch (contentType) {
            case 'pc': {
                try {
                    const id = parseInt(urlPieces[1])
                    const response: AxiosResponse<PrepCheckDTO> = await isa.get(
                        `/public/prep-checks/${id}?overview=true`
                    )

                    setLinkedPrepCheck(response.data)
                    setTimeout(() => {
                        window.location.href = `${env.frontendUriBase}/prep-checks/${response.data.id}`
                    }, 2000)
                } catch (err) {
                    process.env.REACT_APP_DEPLOY_ENV !== 'production' &&
                        console.error(err)
                    window.location.href = `${env.frontendUriBase}/prep-checks`
                }
                break
            }

            case 'prep': {
                try {
                    const id = parseInt(urlPieces[1])
                    const response: AxiosResponse<PrepCheckDTO> = await isa.get(
                        `/public/prep-checks/${id}?overview=true`
                    )

                    setLinkedPrepCheck(response.data)
                    setTimeout(() => {
                        window.location.href = `${env.frontendUriBase}/prep-checks/${response.data.id}`
                    }, 2000)
                } catch (err) {
                    process.env.REACT_APP_DEPLOY_ENV !== 'production' &&
                        console.error(err)
                    window.location.href = `${env.frontendUriBase}/prep-checks`
                }
                break
            }

            default: {
                break
            }
        }
    }

    const routes: RouteProps[] = [
        {
            path: '/',
            element: <LandingPage loading={loading} />,
        },
        {
            path: '/:id',
            element: <LandingPage loading={loading} />,
        },
    ]

    const onSuggestionPress = async (
        slug: string,
        id: number,
        name: string
    ) => {
        logEvent('GO_TO_HAZARD', { id, name })
            .then((status) => {
                if (status !== LoggingStatus.Success) {
                    console.error(`logEvent status: ${status}`)
                }
                window.location.href = `${env.frontendUriBase}/hazards/${slug}`
            })
            .catch((err) => {
                console.error(err)
                window.location.href = `${env.frontendUriBase}/hazards/${slug}`
            })
    }
    const onSearchQuerySubmit = () => {
        navigate(`/?query=${searchQuery}`)
    }
    return (
        <div className={classes.root}>
            {linkedHazard || linkedPrepCheck ? (
                <div
                    style={{
                        height: '100vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {linkedHazard ? (
                        <HazardRedirect
                            name={linkedHazard.name}
                            icon={linkedHazard.icon.src}
                            url={`${env.frontendUriBase}/hazards/${linkedHazard.slug}`}
                        />
                    ) : (
                        linkedPrepCheck && (
                            <PrepCheckRedirect
                                name={linkedPrepCheck.title}
                                icon={linkedPrepCheck.dark_icon?.url}
                                url={`${env.frontendUriBase}/prep-checks/${linkedPrepCheck.id}`}
                            />
                        )
                    )}
                </div>
            ) : linkedHazard === null || linkedPrepCheck === null ? (
                <>
                    <SiteHeader
                        searchQuery={searchQuery}
                        onSearchQueryChange={setSearchQuery}
                        rootHref={env.hazGuideQuickUriBase}
                        options={suggestions}
                        onOptionPress={onSuggestionPress}
                        onSearchQuerySubmit={onSearchQuerySubmit}
                    />
                    <div className={classes.simpleFlex}>
                        <Routes {...props}>
                            {routes.map((route: RouteProps) => (
                                <Route key={route.path} {...route} />
                            ))}
                            {/* <Route element={<NotFound />} /> */}
                        </Routes>
                    </div>
                    <Footer />
                </>
            ) : null}
            <Toaster />
        </div>
    )
}
