interface EnvConfig {
    apiUrl: string
    authUrl: string
    authAPIUrl: string
    pdfUrl: string
    frontendUriBase: string
    hazGuideQuickUriBase: string
}

const ENV: { [key: string]: EnvConfig } = {
    dev: {
        apiUrl: 'http://localhost:5555',
        authUrl: 'http://localhost:4455',
        authAPIUrl: 'http://localhost:4000',
        pdfUrl: 'https://atlas.hazadaptlabs.xyz',
        frontendUriBase: 'http://localhost:3000',
        hazGuideQuickUriBase: 'http://localhost:3000',
    },
    staging: {
        apiUrl: 'https://triton.hazadaptlabs.xyz',
        authUrl: 'https://io.hazadaptlabs.xyz',
        authAPIUrl: 'https://auth.hazadaptlabs.xyz',
        pdfUrl: 'https://atlas.hazadaptlabs.xyz',
        frontendUriBase: 'https://earth.hazadaptlabs.xyz',
        hazGuideQuickUriBase: 'https://moon.hazadaptlabs.xyz',
    },
    beta: {
        apiUrl: 'https://api.hazadapt.com',
        authUrl: 'https://accounts.hazadapt.com',
        authAPIUrl: 'https://iam.hazadapt.com',
        pdfUrl: 'https://cdn.hazadapt.com',
        frontendUriBase: 'https://public-haz-web-vbeta-2023.hazadapt.com',
        hazGuideQuickUriBase: 'https://haz.guide',
    },
    prod: {
        apiUrl: 'https://api.hazadapt.com',
        authUrl: 'https://accounts.hazadapt.com',
        authAPIUrl: 'https://iam.hazadapt.com',
        pdfUrl: 'https://cdn.hazadapt.com',
        frontendUriBase: 'https://app.hazadapt.com',
        hazGuideQuickUriBase: 'https://haz.guide',
    },
    error: {
        apiUrl: '',
        authUrl: '',
        authAPIUrl: '',
        pdfUrl: '',
        frontendUriBase: '',
        hazGuideQuickUriBase: '',
    },
}

export const getEnvVars = (
    env: string | undefined = process.env.REACT_APP_DEPLOY_ENV
) => {
    if (env === 'dev') {
        return ENV.dev
    } else if (env === 'staging') {
        return ENV.staging
    } else if (env === 'beta') {
        return ENV.beta
    } else if (env === 'production') {
        return ENV.prod
    } else {
        return ENV.error
    }
}

export const inDevEnv = () =>
    process.env.REACT_APP_DEPLOY_ENV === 'dev' ||
    process.env.REACT_APP_DEPLOY_ENV === 'staging'
export const inBetaEnv = () => process.env.REACT_APP_DEPLOY_ENV === 'beta'
