import { HazardOverview } from '@hazadapt-git/public-core-base'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface HazardStoreState {
    hazards: HazardOverview[]
}

const INITIAL_STATE: HazardStoreState = {
    hazards: [],
}

const hazardSlice = createSlice({
    name: 'hazards',
    initialState: INITIAL_STATE,
    reducers: {
        setHazards: (state, action: PayloadAction<HazardOverview[]>) => {
            state.hazards = action.payload
        },
    },
})

export const { setHazards } = hazardSlice.actions
export default hazardSlice.reducer
