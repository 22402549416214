import { Card as MuiCard, CardProps as MuiCardProps } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { CSSProperties } from 'react'

import { customBorderRadius } from '../../lib/styles/universal'

export interface CardProps extends MuiCardProps {
    colors: string[]
    innerContainerStyle?: CSSProperties
}

export const Card = React.forwardRef<HTMLDivElement, CardProps>(
    (props: CardProps, ref: React.ForwardedRef<HTMLDivElement>) => {
        const { classes } = useLocalStyles()
        const { colors, innerContainerStyle, ...cardProps } = props
        const rootStyle: CSSProperties = {
            cursor: props.onClick ? 'pointer' : 'default',
            borderRadius: customBorderRadius,
        }
        const style: CSSProperties =
            colors.length === 1
                ? {
                      ...rootStyle,
                      backgroundColor: colors[0],
                  }
                : {
                      ...rootStyle,
                      background: `linear-gradient(${colors.join(', ')})`,
                  }
        return (
            <MuiCard
                {...cardProps}
                ref={ref}
                style={{ ...style, ...props.style }}
            >
                <div
                    className={classes.innerCardContainer}
                    style={innerContainerStyle}
                >
                    {props.children}
                </div>
            </MuiCard>
        )
    }
)

const useLocalStyles = makeStyles()({
    innerCardContainer: {
        padding: '1rem',
        position: 'relative',
        height: '100%',
    },
})
