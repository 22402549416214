import { Link, Typography } from '@mui/material'
import React, { FC } from 'react'

import { customMidGrayColor, altHazRedColor } from '../../lib/styles/universal'

export interface HazardCategoryProps {
    active?: boolean
    name: string
    onPress(...props: any): void
}

export const HazardCategory: FC<HazardCategoryProps> = (
    props: HazardCategoryProps
) => (
    <Link onClick={props.onPress} style={{ textDecoration: 'none' }}>
        <Typography
            sx={{
                paddingLeft: 1,
                paddingRight: 1,
                cursor: 'pointer',
                fontWeight: 500,
                borderBottom: `2px ${
                    props.active ? altHazRedColor : customMidGrayColor
                } solid`,
                color: props.active ? altHazRedColor : customMidGrayColor,
            }}
            alignItems="center"
            justifyContent="center"
            textAlign="center"
        >
            {props.name}
        </Typography>
    </Link>
)
