import axios from 'axios'

import { getEnvVars } from './config'

const { apiUrl } = getEnvVars()

export const isa = axios.create({
    baseURL: apiUrl,
    headers: {
        'Cache-Control': 'no-cache',
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
})
