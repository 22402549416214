import {
    ContentBlock,
    Hazard,
    HazardOverview,
    LanguageCode,
    NativeLanguageDTO,
    TranslatedString,
} from '@hazadapt-git/public-core-base'
import didYouMean from 'didyoumean'
import Fuse from 'fuse.js'
import voca from 'voca'

import { isa } from '../api'

export interface SearchResultsWithDYM {
    hazards: number[]
    suggestedQuery?: string
}

/**
 * Searches for and gets all hazards that sufficiently match the given pattern
 * @param hazards
 * @param query
 * @returns Hazard[]
 */
export const searchHazards = async (
    hazards: HazardOverview[],
    query: string,
    lang: LanguageCode = LanguageCode.ENGLISH
): Promise<HazardOverview[]> => {
    if (query.length === 0) return hazards

    try {
        const response = await isa.get<SearchResultsWithDYM>(
            `/public/guide/search/v2?query=${query}`
        )
        if (response.status === 204) return hazards
        const { hazards: resultIDs } = response.data
        const hazardsToReturn: HazardOverview[] = []
        for (const id of resultIDs) {
            const hazard = hazards.find((h) => h.id === id)
            if (hazard) hazardsToReturn.push(hazard)
        }
        return hazardsToReturn
    } catch (err) {
        console.error(err)

        const options = {
            // isCaseSensitive: false,
            // includeScore: false,
            // shouldSort: true,
            // includeMatches: false,
            // findAllMatches: false,
            // minMatchCharLength: 1,
            // location: 0,
            threshold: 0.2,
            // distance: 100,
            // useExtendedSearch: false,
            ignoreLocation: true,
            // ignoreFieldNorm: false,
            keys: [
                {
                    name: `name.${lang.toString()}`,
                    weight: 4,
                },
                {
                    name: 'types',
                    weight: 3,
                },
                {
                    name: 'tags',
                    weight: 2,
                },
                {
                    name: `description.${lang.toString()}`,
                    weight: 1,
                },
            ],
        }

        const fuse: Fuse<HazardOverview> = new Fuse(hazards, options)
        const search_results: Fuse.FuseResult<HazardOverview>[] =
            fuse.search(query)
        const returned_hazards: HazardOverview[] = [] as HazardOverview[]

        search_results.forEach((sr) => {
            returned_hazards.push(sr.item)
        })

        const keywords: Set<string> = new Set<string>()
        for (const h of hazards) {
            // Add the hazard's name (full and by word),
            // description words, tags, and types
            keywords.add(h.name)
            h.name.split(' ').forEach((k) => keywords.add(k))
            h.description.split(' ').forEach((k) => keywords.add(k))
            h.tags.forEach((k) => keywords.add(k))
            h.types
                .map((t) => t.toString().replace('_', ' '))
                .forEach((k) => keywords.add(k))
        }

        return returned_hazards
    }
}

/**
 * Get the closest hazard query to the submitted query
 * @param query The submitted query
 * @param keywords The list of "good" hazard keywords
 * @returns string
 */
export const getClosestHazardQueryLocally = (
    query: string,
    keywords: string[]
): string | undefined => {
    const lowercaseKeywords = keywords.map((k: string) => k.toLowerCase())
    if (lowercaseKeywords.includes(query)) {
        return undefined
    }
    const results = didYouMean(query, lowercaseKeywords)
    return results ? results.toString() : undefined
}

/**
 * removeLanguagesFromObject
 * Removes specified translations from an object
 * @param obj The object to be filtered
 * @param languages The list of languages to remove
 * @returns TranslatedString
 */
export const filterObjectByLanguage = (
    obj: TranslatedString,
    languages: string[]
): TranslatedString => {
    return Object.keys(obj)
        .filter((key) => !languages.includes(key))
        .reduce((filteredObj: TranslatedString, key: string) => {
            filteredObj[key] = obj[key]
            return filteredObj
        }, {})
}

/**
 * stripLanguagesFromContentBlock
 * Remove specified languages from a content block.
 * @param cb The content block to filter
 * @param languages The languages to filter out
 * @returns ContentBlock
 */
export const stripLanguagesFromContentBlock = (
    cb: ContentBlock,
    languages: LanguageCode[]
) => {
    if (languages.length === 0) return cb
    const langStrings: string[] = languages.map((l: LanguageCode) =>
        l.toString()
    )
    const filteredBlock: ContentBlock = {
        ...cb,
        title: filterObjectByLanguage(cb.title, langStrings),
        header: filterObjectByLanguage(cb.header, langStrings),
        body: filterObjectByLanguage(cb.body, langStrings),
        image: cb.image
            ? {
                  ...cb.image,
                  alt: filterObjectByLanguage(cb.image.alt, langStrings),
              }
            : null,
        block_icon: cb.block_icon
            ? {
                  ...cb.block_icon,
                  alt: filterObjectByLanguage(cb.block_icon.alt, langStrings),
              }
            : null,
    }
    return filteredBlock
}

/**
 * stripLanguagesFromHazard
 * Remove specified languages from a hazard.
 * @param hazard The hazard to filter.
 * @param languages The languages to filter out.
 * @returns Hazard
 */
export const stripLanguagesFromHazard = (
    hazard: Hazard,
    languages: LanguageCode[]
) => {
    if (languages.length === 0) return hazard
    else {
        const langStrings: string[] = languages.map((l: LanguageCode) =>
            l.toString()
        )
        const filteredHazard: Hazard = {
            ...hazard,
            name: filterObjectByLanguage(hazard.name, langStrings),
            description: filterObjectByLanguage(
                hazard.description,
                langStrings
            ),
            content_blocks: hazard.content_blocks.map((cb: ContentBlock) =>
                stripLanguagesFromContentBlock(cb, languages)
            ),
            icon: {
                ...hazard.icon,
                alt: filterObjectByLanguage(hazard.icon.alt, langStrings),
            },
            cover_image: {
                ...hazard.cover_image,
                alt: filterObjectByLanguage(
                    hazard.cover_image.alt,
                    langStrings
                ),
            },
        }
        return filteredHazard
    }
}

/**
 * getLangRadioButtonObjects
 * Builds a list of objects to feed to the radio button list for the language selector
 * @param languages
 * @returns NativeLanguageDTO[]
 */
export const getLangObjects = (
    languages: LanguageCode[]
): NativeLanguageDTO[] => {
    // If this hazard has no languages, return an empty list
    if (languages !== undefined && languages.length === 0) return []
    return Object.keys(LanguageCode)
        .filter(
            (l) =>
                l.length > 2 &&
                (languages === undefined ||
                    languages.includes(
                        LanguageCode[l as keyof typeof LanguageCode]
                    ))
        ) // Only get supported languages
        .map((l) => {
            const val = LanguageCode[l as keyof typeof LanguageCode]
            const regionLang = new Intl.DisplayNames([val.toString()], {
                type: 'language',
            })
            const languageName: string =
                val.toString() === 'vi'
                    ? regionLang.of(val.toString()) ?? LanguageCode.ENGLISH
                    : voca.titleCase(regionLang.of(val.toString())) // Handles weird title-casing for Vietnamese
            return {
                title: languageName,
                value: val,
            }
        })
}
