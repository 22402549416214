import React, { FC, CSSProperties } from 'react'

import LogoLight from '../../assets/images/hazadapt-red-black.png'
import LogoDark from '../../assets/images/hazadapt-red-white.png'

export interface CompanyLogoProps {
    dark?: boolean
    width?: number | string
    style?: CSSProperties
    onClick?(...props: any): void
}

export const CompanyLogo: FC<CompanyLogoProps> = (props: CompanyLogoProps) => (
    <img
        src={props.dark ? LogoDark : LogoLight}
        style={{
            ...props.style,
            width: props.width || '10rem',
            height: 'auto',
        }}
        onClick={props.onClick}
        alt="HazAdapt logo"
    />
)
