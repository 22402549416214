import { Typography } from '@mui/material'
import React, { FC } from 'react'

import { customDarkBlueColor, useStyles } from '../../lib/styles/universal'
import { useWindowSizeUp } from '../../lib/utils'
import {
    HazardCategoryGrid,
    HazardCardGrid,
    HazardCardGridProps,
    HazardCategoryGridProps,
} from '../organisms'

interface LandingPageTemplateProps
    extends HazardCategoryGridProps,
        HazardCardGridProps {}

export const LandingPageTemplate: FC<LandingPageTemplateProps> = (
    props: LandingPageTemplateProps
) => {
    const { classes } = useStyles()
    const mediumWindowOrLarger = useWindowSizeUp('md')
    const {
        categories,
        activeCategories,
        onCategoryPress,
        ...hazardCardGridProps
    } = props
    const hazardCategoryGridProps = {
        categories,
        activeCategories,
        onCategoryPress,
    }
    return (
        <div
            className={classes.page}
            style={{
                paddingLeft: '1rem',
                paddingRight: '1rem',
                marginBottom: '3rem',
            }}
        >
            <Typography
                variant="h1"
                width="100%"
                fontSize={mediumWindowOrLarger ? '2.5rem' : undefined}
                textAlign="center"
                color={customDarkBlueColor}
                paddingBottom="2rem"
            >
                Hazard Guide
            </Typography>
            <div
                style={{
                    alignSelf: 'center',
                    marginBottom: '3rem',
                }}
            >
                <HazardCategoryGrid {...hazardCategoryGridProps} />
            </div>
            <HazardCardGrid {...hazardCardGridProps} />
        </div>
    )
}
