import { Typography, CircularProgress } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC, useEffect } from 'react'
import { customDarkBlueColor } from '../../lib/styles/universal'
import { useWindowSizeUp } from '../../lib/utils'

interface HazardRedirectProps {
    name: string
    icon: string
    url: string
}

export const HazardRedirect: FC<HazardRedirectProps> = (
    props: HazardRedirectProps
) => {
    useEffect(() => {
        document.title = `Redirecting to ${props.name} Guide... - HazAdapt`
    }, [props.name])
    const smallWindowOrLarger = useWindowSizeUp('sm')
    const useLocalStyles = makeStyles()({
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        icon: {
            width: 'auto',
            height: smallWindowOrLarger ? '7rem' : '6rem',
            maxWidth: smallWindowOrLarger ? '7rem' : '6rem',
        },
    })
    const { classes } = useLocalStyles()

    return (
        <div className={classes.container}>
            <img
                className={classes.icon}
                src={`data:image/svg+xml;base64,${btoa(props.icon)}`}
                alt={`${props.name} icon`}
            />
            <Typography variant="h3" p="1rem" textAlign="center">
                Going to safety instructions for {props.name}...
            </Typography>
            <CircularProgress sx={{ color: customDarkBlueColor }} />
            <div
                style={{
                    position: 'absolute',
                    bottom: '2rem',
                    paddingLeft: '2rem',
                    paddingRight: '2rem',
                }}
            >
                <Typography variant="h5" fontStyle="normal" textAlign="center">
                    Having trouble getting to safety instructions? Click{' '}
                    <a href={props.url}>here</a>.
                </Typography>
            </div>
        </div>
    )
}
