import {
    twitterURL,
    facebookURL,
    instagramURL,
    linkedinURL,
    websiteURL,
    hazAdaptOnAppStoreURL,
    hazAdaptOnGooglePlayURL,
} from '@hazadapt-git/public-core-base'
import { Typography, Link } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'
import {
    FaTwitter,
    FaFacebook,
    FaInstagram,
    FaLinkedinIn,
    FaGlobe,
} from 'react-icons/fa'

import AppStore from '../../assets/images/DownloadOnTheAppStore.svg'
import GooglePlay from '../../assets/images/GetOnGooglePlay.svg'
import { customDarkBlueColor } from '../../lib/styles/universal'
import {
    logFacebookHit,
    logAppStoreLinkHit,
    logGooglePlayLinkHit,
    logWebsiteHit,
    logInstagramHit,
    logLinkedinHit,
    logTwitterHit,
} from '../../lib/utils'
import { AppIcon, CompanyLogo } from '../atoms'

interface FooterProps {}

export const Footer: FC<FooterProps> = (props: FooterProps) => {
    const { classes } = useLocalStyles()
    return (
        <div
            style={{
                width: '100%',
                backgroundColor: customDarkBlueColor,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <CompanyLogo dark style={{ margin: '1rem' }} />
            <AppIcon />
            <Typography
                variant="h4"
                pt={2}
                textAlign="center"
                sx={{ color: 'white' }}
            >
                Access the Hazard Guide, even when you're offline.
            </Typography>
            <div style={{ display: 'flex' }}>
                <Link
                    href={hazAdaptOnAppStoreURL}
                    target="_blank"
                    onClick={logAppStoreLinkHit}
                >
                    <img
                        src={AppStore}
                        className={classes.appStoreIcon}
                        style={{
                            paddingRight: '1rem',
                        }}
                        onClick={logAppStoreLinkHit}
                        alt="Download on the App Store icon"
                    />
                </Link>
                <Link
                    href={hazAdaptOnGooglePlayURL}
                    target="_blank"
                    onClick={logGooglePlayLinkHit}
                >
                    <img
                        src={GooglePlay}
                        className={classes.appStoreIcon}
                        style={{
                            paddingLeft: '1rem',
                        }}
                        onClick={logGooglePlayLinkHit}
                        alt="Get It on Google Play icon"
                    />
                </Link>
            </div>
            <Typography pt={2} textAlign="center" sx={{ color: 'white' }}>
                Copyright &copy; {new Date().getFullYear()} HazAdapt, Inc.
            </Typography>
            <div className={classes.iconContainer}>
                <div className={classes.icon}>
                    <Link
                        href={twitterURL}
                        target="_blank"
                        onClick={logTwitterHit}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <FaTwitter color={customDarkBlueColor} size="1.2rem" />
                    </Link>
                </div>
                <div className={classes.fbIcon}>
                    <Link
                        href={facebookURL}
                        target="_blank"
                        onClick={logFacebookHit}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <FaFacebook color="white" size="2rem" />
                    </Link>
                </div>
                <div className={classes.icon}>
                    <Link
                        href={instagramURL}
                        target="_blank"
                        onClick={logInstagramHit}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <FaInstagram
                            color={customDarkBlueColor}
                            size="1.2rem"
                        />
                    </Link>
                </div>
                <div className={classes.icon}>
                    <Link
                        href={linkedinURL}
                        target="_blank"
                        onClick={logLinkedinHit}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <FaLinkedinIn
                            color={customDarkBlueColor}
                            size="1.2rem"
                        />
                    </Link>
                </div>
                <div className={classes.icon}>
                    <Link
                        href={websiteURL}
                        target="_blank"
                        onClick={logWebsiteHit}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <FaGlobe color={customDarkBlueColor} size="1.2rem" />
                    </Link>
                </div>
            </div>
        </div>
    )
}

const useLocalStyles = makeStyles()({
    iconContainer: {
        display: 'flex',
        alignSelf: 'center',
        alignItems: 'center',
    },
    fbIcon: {
        margin: '1rem',
        marginLeft: '0.5rem',
        marginRight: '0.5rem',
        cursor: 'pointer',
    },
    icon: {
        backgroundColor: 'white',
        borderRadius: '5rem',
        padding: '0.4rem',
        margin: '1rem',
        marginLeft: '0.5rem',
        marginRight: '0.5rem',
        cursor: 'pointer',
    },
    appStoreIcon: {
        height: '3rem',
        width: 'auto',
    },
})
