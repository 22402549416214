import {
    HazardMultiLangDTO,
    HazardOverviewResponse,
    LanguageCode,
} from '@hazadapt-git/public-core-base'
import { AxiosResponse } from 'axios'

import { isa } from '../api'
import { store } from '../store'
import { setHazards } from '../slices'

/**
 * Gets the latest hazards from the cloud,
 * falling back to locally stored content if necessary.
 */
export const getHazards = async (
    lang: LanguageCode = LanguageCode.ENGLISH
): Promise<void> => {
    // get latest hazards
    const response: AxiosResponse<HazardOverviewResponse> = await isa.get(
        `/public/v2/hazards?lang=${lang}`
    )
    store.dispatch(setHazards(response.data.hazards))
}

export const getHazard = async (
    identifier: string,
    lang: LanguageCode = LanguageCode.ENGLISH
): Promise<HazardMultiLangDTO> => {
    const response: AxiosResponse<HazardMultiLangDTO> = await isa.get(
        `/public/guide/hazard/${identifier}?languages=${lang}`
    )
    return response.data
}
