import { HazardOverview, LoggingStatus } from '@hazadapt-git/public-core-base'
import * as clipboard from 'clipboard-polyfill'
import React, { FC } from 'react'
import { IoIosLink } from 'react-icons/io'

import { HazardCardDTO, LandingPageTemplate } from '../components'
import { getEnvVars } from '../lib/config'
import { RootState, useAppSelector } from '../lib/store'
import { successColor, primaryIconSize } from '../lib/styles/universal'
import { logEvent, toast } from '../lib/utils'

interface LandingPageProps {
    loading: boolean
}

const env = getEnvVars()

export const LandingPage: FC<LandingPageProps> = (props: LandingPageProps) => {
    const categories = ['Health', 'Crime', 'Common Incidents']
    const [activeCategories, setActiveCategories] = React.useState<string[]>([])
    const hazards = useAppSelector((state: RootState) => state.hazards.hazards)

    const [hazardCards, setHazardCards] = React.useState<HazardCardDTO[]>([])
    const [menuOpen, setMenuOpen] = React.useState<number | undefined>()

    React.useEffect(() => {
        const cards: HazardCardDTO[] = []
        const activeHazards: HazardOverview[] = []
        for (const h of hazards) {
            if (
                activeCategories.length === 0 ||
                h.types.filter((t) =>
                    t === 'Medical'
                        ? activeCategories.includes('Health')
                        : t === 'Common_Incident'
                        ? activeCategories.includes('Common Incidents')
                        : activeCategories.includes(t.toString())
                ).length > 0
            ) {
                activeHazards.push(h)
            }
        }
        for (const h of activeHazards) {
            const card: HazardCardDTO = {
                id: h.id,
                icon: h.icon.src,
                name: h.name,
                slug: h.slug,
                description: h.description,
                filterChips: [], // if we add filtering to the quick landing, logic will be added here to match the full web app
            }
            cards.push(card)
        }
        const sortedCards = cards.sort((a, b) => (a.slug < b.slug ? -1 : 1))
        setHazardCards(sortedCards)
    }, [hazards, activeCategories])

    const onHazardPress = (slug: string, id: number, name: string) => {
        logEvent('GO_TO_HAZARD', { id, name })
            .then((status) => {
                if (status !== LoggingStatus.Success) {
                    console.error(`logEvent status: ${status}`)
                }
                window.location.href = `${env.frontendUriBase}/hazards/${slug}`
            })
            .catch((err) => {
                console.error(err)
                window.location.href = `${env.frontendUriBase}/hazards/${slug}`
            })
    }

    const onCategoryPress = (category: string) => {
        const active: string[] = [...activeCategories]
        const index: number = active.findIndex((c: string) => c === category)
        if (index !== -1) {
            active.splice(index, 1)
        } else {
            active.push(category)
        }
        setActiveCategories(active)
    }

    const onOptionsPress = (id: number) => {
        setMenuOpen(id)
    }

    const onOptionsClose = () => {
        setMenuOpen(undefined)
    }

    const onShare = (id: number): void => {
        const hazard = hazards.find((h) => h.id === id)
        if (hazard) {
            logEvent('SHARE_HAZARD', {
                id: hazard.id,
                name: hazard.name,
            })
            const link = `${env.hazGuideQuickUriBase}/${hazard.id}`
            clipboard
                .writeText(link)
                .then(() => {
                    toast(
                        'Link copied to clipboard!',
                        <IoIosLink
                            color={successColor}
                            size={primaryIconSize}
                        />
                    )
                })
                .catch(console.error)
        }
    }

    return (
        <LandingPageTemplate
            categories={categories}
            activeCategories={activeCategories}
            data={hazardCards}
            onCategoryPress={onCategoryPress}
            onHazardPress={onHazardPress}
            onOptionsPress={onOptionsPress}
            onOptionsClose={onOptionsClose}
            onShare={onShare}
            openMenu={menuOpen}
            loading={props.loading}
        />
    )
}
