import { IconButton, Typography, Grid } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import React, { FC } from 'react'
import { AspectRatio } from 'react-aspect-ratio'
import { IoIosLink } from 'react-icons/io'

import { customDarkBlueColor } from '../../lib/styles/universal'
import {
    useWindowSizeDown,
    useWindowSizeExact,
    useWindowSizeUp,
} from '../../lib/utils'
import { Card } from '../atoms'
import { ChipConfig } from '@hazadapt-git/public-core-base'

export interface HazardCardDTO {
    id: number
    icon: string
    name: string
    slug: string
    description: string
    filterChips: ChipConfig[]
    bookmarked?: boolean
}

interface HazardCardProps extends HazardCardDTO {
    onShare(id: number): void
    onOptionsPress(
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        id: number
    ): void
    onOptionsClose(): void
    onPress(slug: string, id: number, name: string): void
    optionsOpen: boolean
    anchorEl: HTMLElement | null
}

export const HazardCard: FC<HazardCardProps> = (props: HazardCardProps) => {
    const windowSize = {
        xs: useWindowSizeExact('xs'),
        sm: useWindowSizeExact('sm'),
        md: useWindowSizeExact('md'),
        lg: useWindowSizeExact('lg'),
        xl: useWindowSizeExact('xl'),
    }
    const smallWindowOrLarger = useWindowSizeUp('sm')
    const smallWindowOrSmaller = useWindowSizeDown('md')
    const useLocalStyles = makeStyles()({
        hazardCard: {
            cursor: 'pointer',
            flex: 1,
        },
        actionButtonContainer: {
            position: 'absolute',
            top: '0.1rem',
            right: '0.1rem',
        },
        icon: {
            width: 'auto',
            height: smallWindowOrLarger ? '7rem' : '6rem',
            maxWidth: smallWindowOrLarger ? '7rem' : '6rem',
            paddingRight: smallWindowOrSmaller ? '1rem' : 0,
        },
    })

    const { classes } = useLocalStyles()

    const aspectRatio = windowSize['xs'] ? 2 : windowSize['sm'] ? 4 : 1

    return (
        <AspectRatio ratio={aspectRatio} style={{ width: '100%' }}>
            <Card
                colors={['#f6f6f6']}
                className={classes.hazardCard}
                innerContainerStyle={{ padding: 0, flex: 1, height: '100%' }}
            >
                <div className={classes.actionButtonContainer}>
                    {/* <IconButton
                        onClick={(e) => props.onOptionsPress(e, props.id)}
                    >
                        <MoreVert sx={{ color: '#9BB3C2' }} />
                    </IconButton>
                    <OptionsView
                        items={[
                            {
                                key: `share_link_${props.id}`,
                                children: (
                                    <Typography
                                        sx={{ p: 1 }}
                                        onClick={props.onShare}
                                    >
                                        Share Link
                                    </Typography>
                                ),
                            },
                        ]}
                        open={props.optionsOpen || false}
                        anchorEl={props.anchorEl}
                        onClose={props.onOptionsClose}
                    /> */}
                    <IconButton onClick={() => props.onShare(props.id)}>
                        <IoIosLink color={customDarkBlueColor} />
                    </IconButton>
                </div>
                <Grid
                    container
                    alignItems="center"
                    sx={{
                        height: '100%',
                        padding: '1rem',
                        textAlign: smallWindowOrSmaller ? 'center' : 'left',
                        cursor: 'pointer',
                    }}
                    onClick={() =>
                        props.onPress(props.slug, props.id, props.name)
                    }
                >
                    <Grid
                        item
                        xs={4}
                        sm={3}
                        md={12}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                    >
                        <img
                            src={`data:image/svg+xml;base64,${btoa(
                                props.icon
                            )}`}
                            className={classes.icon}
                            alt={`${props.name} icon`}
                        />
                    </Grid>
                    <Grid item xs>
                        <Typography
                            variant="h3"
                            textAlign={smallWindowOrSmaller ? 'left' : 'center'}
                            sx={{ p: 0 }}
                        >
                            {props.name}
                        </Typography>
                    </Grid>
                </Grid>
            </Card>
        </AspectRatio>
    )
}
