import { LoggingStatus } from '@hazadapt-git/public-core-base'
import { AxiosResponse } from 'axios'

import { isa } from '../api'

// Each of these values corresponds with a value in the LoggingEvent enum in ISA
export type LoggingEvent =
    | 'OPEN_APP' // The user opens the app
    // | 'OPEN_PAGE' // The user goes to a specified page
    // | 'OPEN_EMERGENCY_CALL_HELPER' // The user opens the Emergency Call Helper
    // | 'VIEW_HAZARD' // The user views a specified hazard
    // | 'VIEW_CONTENT_BLOCK' // The user views a specified content block
    | 'SEARCH' // The user submits a search query
    // | 'SELECT_FILTER' // The user enables a filter in the Hazard Guide
    // | 'SELECT_HAZARD_STAGE' // The user switches to a particular hazard stage (Prepare/React/Recover)
    // | 'SELECT_CONTENT_BOOSTER' // The user enables a content booster for a specified hazard
    // | 'ADD_BOOKMARK' // The user bookmarks a specified hazard
    // | 'REMOVE_BOOKMARK' // The user removes a specified hazard from their bookmarks
    // | 'OPEN_FAQ_BLOCK' // The user opens a specified FAQ block
    | 'OPEN_PRIVACY_POLICY' // The user opens the privacy policy
    | 'OPEN_TERMS_CONDITIONS' // The user views the terms and conditions
    | 'REPORT_A_BUG' // The user presses the Report a Bug button from a specified page
    | 'REPORT_TRANSLATION_ERROR' // The user presses the Report a Translation Bug from a specified page
    | 'REQUEST_HAZARD' // The user requests a hazard from a specified page
    | 'REQUEST_FEATURE' // The user reqeusts a new feature from a specified page
    | 'SUBMIT_FEEDBACK' // The user opens the Submit Feedback form
    | 'SWITCH_LANGUAGE' // The user switches to another language
    | 'OPEN_TWITTER' // The user presses the Twitter icon to go to our Twitter
    | 'OPEN_FACEBOOK' // The user presses the Facebook icon to go to our Facebook
    | 'OPEN_INSTAGRAM' // The user presses the Instagram icon to go to our Instagram
    | 'OPEN_LINKEDIN' // The user presses the LinkedIn icon to go to our LinkedIn
    | 'OPEN_WHY_HAZADAPT' // The user presses the "Why HazAdapt?" link
    | 'OPEN_WEBSITE' // The user presses the website icon to go to our website
    | 'GO_TO_APP_IN_APPLE_APP_STORE' // The user presses the Download on the App Store button in the footer
    | 'GO_TO_APP_IN_GOOGLE_PLAY' // The user presses the Get It On Google Play button in the footer
    | 'SHARE_HAZARD' // The user copies a link to a hazard

    /** haz.guide Quick Landing-specific logging events */
    | 'GO_TO_HAZARD' // The user goes to a specific hazard

/**
 * logEvent
 * Log an event; send the event data to ISA to send to Amplitude servers
 * @param event_type: event name; each possible value corresponds to an enum value on ISA
 * @param event_properties: any other data to send to ISA for the event
 * @returns The success status of the logging event
 */
export const logEvent = async (
    event_type: LoggingEvent,
    event_properties?: { [key: string]: any },
    user_proprties?: { [key: string]: any }
): Promise<LoggingStatus> => {
    const params = {
        event_type,
        event_properties: {
            ...event_properties,
        },
        user_properties: {
            ...user_proprties,
            zip: null, // otherwise send null
        },
        os_name: 'web',
        app_version: process.env.REACT_APP_VERSION || undefined,
        time: new Date().getTime(),
    }
    try {
        const response: AxiosResponse<LoggingStatus> = await isa.post(
            // send the logging event to ISA
            '/haz-guide-quick/analytics/log',
            params
        )
        return response.data // return the logging event's success status
    } catch (err) {
        process.env.REACT_APP_DEPLOY_ENV === 'dev' && console.error(err)
        return LoggingStatus.Failed
    }
}
