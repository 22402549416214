import { colors } from '@hazadapt-git/public-core-base'
import { createTheme, SxProps, Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

export const customHazTealColor = colors.secondary.BONDI // color for a selected tab and input titles on settings page
export const customDarkBlueColor = colors.primary.CERULEAN // our custom blue branding color
export const customBlackColor = colors.grays.CHARCOAL // our dark gray color
export const customLightGrayColor = colors.grays.SMOOTH // our off-white color
export const customMidGrayColor = colors.grays.NIMBUS // our mid-gray color
export const altHazRedColor = colors.primary.CORAL // a more vivid variant of our haz red
export const transparentBkgdColor = 'rgba(0,0,0,0)' // transparent color
export const successColor = colors.primary.WAIKATO // color for success text or icons
export const errorColor = colors.primary.CORAL // color for error text on page
export const customBorderRadius = '1rem' // universal border radius
export const customRoundedContainerBorderRadius = '1rem' // border radius for a swoop
export const primaryIconSize = '2rem' // toast icon size
export const unavailableChipColor = colors.grays.NIMBUS // color for an unavailable but selected chip
export const unavailableChipTextColor = colors.grays.SILVER // color for the text of an unavailable but selected chip
export const prepCheckColor = colors.primary.HELIOTROPE
export const localContentColor = '#0A8200'

export const applyColorToPickerSubcomponents = (
    color?: string,
    type: 'outlined' | 'filled' | 'standard' = 'outlined'
): SxProps<Theme> => ({
    '.MuiOutlinedInput-notchedOutline': {
        borderColor: color,
        borderWidth: type === 'filled' ? 0 : '1px',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: color,
        borderWidth: type === 'filled' ? 0 : '1px',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: color,
        borderWidth: type === 'filled' ? 0 : '1px',
    },
    '&:active .MuiOutlinedInput-notchedOutline': {
        borderColor: color,
        borderWidth: type === 'filled' ? 0 : '1px',
    },
    '.MuiSelect-icon': {
        color,
    },
    '&.MuiFilledInput-root.MuiInputBase-root': {
        backgroundColor:
            type === 'filled' ? `${colors.grays.BLANC}2A` : undefined,
    },
})

export const breakpoints = {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1875,
}

export const theme: Theme = createTheme({
    breakpoints: {
        values: breakpoints,
    },
    typography: {
        fontFamily: [
            'Rubik',
            '-apple-system',
            'BlinkMacSystemFont',
            'Segoe UI',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            'Fira Sans',
            'Droid Sans',
            'Helvetica Neue',
            'sans-serif',
        ].join(','),
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 600,
        h1: {
            fontSize: '2rem',
            fontWeight: 600,
            paddingBottom: '0.625rem',
            color: 'black',
        },
        h2: {
            fontSize: '1.8rem',
            fontWeight: 600,
            paddingBottom: '0.625rem',
            color: 'black',
        },
        h3: {
            fontSize: '1.5rem',
            fontWeight: 500,
            paddingBottom: '0.625rem',
            color: 'black',
        },
        h4: {
            fontSize: '1.2rem',
            fontWeight: 500,
            paddingBottom: '0.625rem',
            color: 'black',
        },
        h5: {
            fontSize: '1rem',
            fontStyle: 'italic',
            paddingBottom: '0.625rem',
            color: 'black',
        },
    },
    palette: {
        primary: {
            main: customDarkBlueColor,
        },
        success: {
            main: successColor,
        },
        error: {
            main: altHazRedColor,
        },
        secondary: {
            main: customBlackColor,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 100,
                    fontSize: '1rem',
                    textTransform: 'none',
                    fontWeight: 500,
                    boxShadow: '0 0 0 0',
                    paddingLeft: '1.5rem',
                    paddingRight: '1.5rem',
                },
                outlined: {
                    color: 'black',
                },
                text: {
                    fontWeight: 400,
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: '#339AF0',
                    cursor: 'pointer',
                    textDecorationColor: '#339AF0',
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    width: '90%',
                    margin: '1rem',
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: '0 0 0 0',
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    color: customMidGrayColor,
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: '0.5rem',
                },
            },
        },
    },
})

export const useStyles = makeStyles()({
    root: {
        width: '100vw',
        height: '100%',
        minHeight: '100vh',
        overflowX: 'hidden',
        display: 'flex',
        flexFlow: 'column',
        position: 'relative',
        flex: 1,
    },
    simpleFlex: {
        flex: 1,
    },
    page: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '1.5rem',
        position: 'relative',
        flex: 1,
        height: '100%',
    },
    backgroundFill: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: customDarkBlueColor,
    },
    baseContainer: {
        flex: 1,
        backgroundColor: 'white',
    },
    centeredText: {
        textAlign: 'center',
    },
    disabledText: {
        opacity: 0.5,
        color: 'black',
    },
    text: {
        fontSize: '1.5rem',
        color: 'black',
    },
    error: {
        borderColor: altHazRedColor,
        color: errorColor,
    },
    pageHeaderOverride: {
        paddingLeft: '1rem',
    },
    pageHeaderBothSidesOverride: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
    },
    pageHeaderBothSides: {
        paddingLeft: '1rem',
        paddingRight: '1rem',
    },
    bottomRightRoundedContainer: {
        borderBottomRightRadius: customRoundedContainerBorderRadius,
    },
    bottomLeftRoundedContainer: {
        borderBottomLeftRadius: customRoundedContainerBorderRadius,
    },
    topRightRoundedContainer: {
        borderTopRightRadius: customRoundedContainerBorderRadius,
    },
    topLeftRoundedContainer: {
        borderTopLeftRadius: customRoundedContainerBorderRadius,
    },
    appCopyrightMessage: {
        alignItems: 'center',
        color: 'black',
        textAlign: 'center',
    },
    appVersionText: {
        fontWeight: 500,
        color: 'black',
        fontSize: '1.125rem',
        textAlign: 'center',
    },
    appIcon: {
        height: '5rem',
        width: '5rem',
        borderRadius: '0.3125rem',
    },
    profilePicture: {
        backgroundColor: transparentBkgdColor,
        height: '1.25rem',
        width: '1.25rem',
    },
    accessory: {
        borderColor: transparentBkgdColor,
        backgroundColor: 'white',
        elevation: 5,
        borderRadius: customBorderRadius,
    },
    card: {
        borderRadius: '1rem',
        padding: '0.2rem',
    },
    emergencyBtn: {
        width: '2.3rem',
        height: '2.3rem',
    },
    helpIcon: {
        alignSelf: 'flex-start',
        justifySelf: 'flex-start',
    },
    whatToKnow911Card: {
        border: '1px #497D9C solid',
    },
})
