import { ThemeProvider } from '@mui/material'
import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { store } from './lib/store'
import { theme } from './lib/styles/universal'
import { Router } from './routes'

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <BrowserRouter>
                    <Router />
                </BrowserRouter>
            </Provider>
        </ThemeProvider>
    )
}

export default App
